export default {
    filters: {
        formatDate: function(date){
            return Vue.moment(date).format('MM/DD/YY');
        }
    },
    props: {
        bankAccounts: {
            type: Array,
            required: true
        }
    },
    data: data,
    computed: getComputed(),
    methods: getMethods()
};

function data(){
    return {
        errorMessages: [],

        transaction: {},
        isSaving: false,

        selectedAccount: null,
        transactionDate: null,
        transactionDescription: '',
        transactionAmount: '',
    };
}

function getComputed(){
    return {
        isSavingDisabled,
        isAddingTransaction,
        accountSelectOptions,
        isDirty,
    };

    function isSavingDisabled(){
        const dataValid = this.selectedAccount && this.selectedAccount.value && this.transactionDate && this.transactionDescription && this.transactionAmount;
        return !dataValid || this.isSaving;
    }

    function isAddingTransaction(){
        return !this.transaction.id;
    }

    function accountSelectOptions(){
        const vm = this;
        const nullOptions = [{ label: 'Select account', value: null }];

        const selectableAccountOptions = vm.bankAccounts.map((bankAccount) => {
            return {
                label: `${bankAccount.name || bankAccount.institution_account?.name || 'Unnamed'} •••${bankAccount.institution_account?.mask || ''}`,
                value: bankAccount
            };
        });
        return nullOptions.concat(selectableAccountOptions);
    }

    function isDirty(){
        const vm = this;
        if(vm.isAddingTransaction){
            return false;
        }

        const dataUpdated =
            vm.transactionDate.toString() !== new Date(vm.transaction.remote_transaction_date).toString() ||
            vm.transactionAmount !== 0 - vm.transaction.amount ||
            vm.transactionDescription !== vm.transaction.merchant;

        return dataUpdated;
    }
}

function getMethods(){
    return {
        openModal,
        hideModal,
        initialize,
        displayApiErrors,
        save,
        deleteTransaction,
        confirmUnsavedChanges,
    };

    function initialize(){
        const vm = this;
        if(vm.isAddingTransaction){
            vm.selectedAccount = { 'label': 'Select account', value: null };
            vm.transactionDate = null;
            vm.transactionDescription = '';
            vm.transactionAmount = '';
        } else {
            vm.selectedAccount = vm.accountSelectOptions.filter(option => option.value?.id === vm.transaction.bank_account_id)[0];
            vm.transactionDate = new Date(vm.transaction.remote_transaction_date);
            vm.transactionDescription = vm.transaction.merchant;
            vm.transactionAmount = 0 - vm.transaction.amount;
        }

        vm.isSaving = false;
    }

    function openModal(transaction){
        const vm = this;
        vm.transaction = JSON.parse(JSON.stringify(transaction || {}));
        vm.initialize();
        vm.$refs.customTransactionModal.show();
    }

    function save(){
        const vm = this;
        const payload = {
            id: vm.transaction.id,
            bank_account_id: vm.selectedAccount.value.id,
            merchant: vm.transactionDescription,
            amount: -vm.transactionAmount,
            remote_transaction_date: vm.transactionDate,
            note: 'Added manually',
        };

        vm.isSaving = true;
        Vue.appApi().authorized().bankAccount(payload.bank_account_id).transaction(payload.id).storeTransaction(payload).then(onSaveSuccess).catch(vm.displayApiErrors);
        function onSaveSuccess(){
            vm.hideModal();
            vm.$emit('close');
        }
    }

    function deleteTransaction(){
        const vm = this;
        const payload = {};
        const isReportPage = vm.$route.name === 'reports';
        if(isReportPage){
            payload.keep_balance = true;
        }

        Vue.appApi().authorized().bankAccount(vm.transaction.bank_account_id).transaction(vm.transaction.id).removeTransaction(payload)
            .then(closeModal)
            .catch(vm.displayApiErrors)
            .finally(resetDeletingState);

        function closeModal(){
            vm.hideModal();
            vm.$emit('close');
        }

        function resetDeletingState(){
            vm.$refs.confirmDeleteModal.hide();
        }
    }

    function hideModal(){
        const vm = this;
        vm.selectedAccount = null;
        vm.transactionAmount = '';
        vm.transactionDescription = '';
        vm.transactionDate = null;
        vm.transaction = {};
        vm.$refs.confirmUnsavedChangesModal.hide();
        vm.$refs.customTransactionModal.hide();
    }

    function displayApiErrors(err){
        const vm = this;
        if(err.appMessage){
            vm.errorMessages.push(err.appMessage);
        }
    }

    function confirmUnsavedChanges(event){
        const vm = this;
        if(vm.isDirty){
            event.preventDefault();
            vm.$refs.confirmUnsavedChangesModal.show();
        }
    }
}
