import draggable from 'vuedraggable';
import AccountScheduleModal from 'vue_root/components/account-schedule-modal/account-schedule-modal';
import SplitTransactionModal from 'vue_root/authorized/subscriber/components/split-transaction-modal/split-transaction-modal';
import MoneyMoverModal from 'vue_root/authorized/subscriber/components/money-mover-modal/money-mover-modal.vue';
import CustomTransactionModal from 'vue_root/authorized/subscriber/components/custom-transaction-modal/custom-transaction-modal';
import PastTransactions from 'vue_root/authorized/subscriber/components/past-transactions/past-transactions';
import TransactionAutomationModal from 'vue_root/authorized/subscriber/components/transaction-automation-modal/transaction-automation-modal.vue';
import { cloneDeep } from 'lodash';
import formatAsDecimal from 'vue_root/mixins/formatAsDecimal.mixin';
import sortBankAccounts from 'vue_root/mixins/sortBankAccounts.mixin.js';

export default {
    components: {
        draggable,
        SplitTransactionModal,
        AccountScheduleModal,
        MoneyMoverModal,
        CustomTransactionModal,
        PastTransactions,
        TransactionAutomationModal,
    },
    filters: {
        formatDate: function(date){
            return Vue.moment(date).format('MM/DD/YY');
        }
    },
    mixins: [formatAsDecimal, sortBankAccounts],
    data: data,
    computed: getComputed(),
    watch: getWatchers(),
    created: created,
    beforeDestroy,
    methods: getMethods(),
};

function created(){
    const vm = this;
    vm.isInitializingView = true;

    vm.refreshAllData(true).finally(resetLoadingState);
    window.addEventListener('resize', vm.toggleDragAndDropFeature);
    vm.toggleDragAndDropFeature();

    function resetLoadingState(){
        vm.isInitializingView = false;
        vm.isFinishedAssigningAllTransactions = !vm.pendingAssignments.length;
    }
}

function beforeDestroy(){
    const vm = this;
    if(vm.isBankAccountStoreOutOfSync){
        vm.$store.dispatch('authorized/bankAccounts/FETCH_BANK_ACCOUNTS');
    }
    window.removeEventListener('resize', vm.toggleDragAndDropFeature);

}

function data(){
    return {
        pendingAssignments: [],
        assignableAccounts: [],
        accountCardExpanded: [],
        apiErrors: [],
        isInitializingView: false,
        isBankAccountStoreOutOfSync: false,
        selectedTransactions: [],
        selectedAccount: { label: 'Choose a bucket', value: null },
        bulkAssignmentError: '',
        isBulkAssigningTransactions: false,
        isMobileScreenSize: false,
        isFinishedAssigningAllTransactions: true,
        isIOS: window.appEnv.clientPlatform === 'ios',
        selectedBankAccount: null,
        allSavingsAccounts: [],
        currentSavingsAccount: {},
        chartData: {
            labels: [],
            series: [],
        },
        chartOptions: {
            donut: true,
            donutSolid: true,
            donutWidth: 160,
            ignoreEmptyValues: true,
            showLabel: true,
            startAngle: 105,
        },
        selectAllTransactions: false,
    };
}

function getComputed(){
    return {
        fetchingTransactions(){
            return this.$store.state.authorized.transactions.isFetchingTransactions;
        },
        assignableTransactions(){
            const vm = this;
            return JSON.parse(JSON.stringify(vm.$store.state.authorized.transactions.unassignedTransactions)).filter(filterByCurrentSavingsAccount);

            function filterByCurrentSavingsAccount(transaction){
                return transaction.bank_account_id === vm.currentSavingsAccount.id;
            }
        },
        sortedAssignableAccounts(){
            const vm = this;
            return vm.assignableAccounts.map(addAttribute)
                .filter(({ id }) => id).sort(vm.byModifiedStoreOrder);

            function addAttribute(account){
                const hasAccountSchedule = account.slug !== 'income_deposit' && account.slug !== 'cc_payoff';
                account.hasAccountSchedule = hasAccountSchedule;
                return account;
            }
        },
        isFetchingDataFromPlaid(){
            return this.$store.state.authorized.isFetchingDataFromPlaid;
        },
        pendingAssignmentsByDate(){
            const vm = this;
            return vm.pendingAssignments.sort(vm.sortByTransactionDate);
        },
        totalOfSelectedTransactions(){
            const vm = this;
            return vm.selectedTransactions.reduce(sumSelectedTransactions, new Decimal(0)).toDecimalPlaces(2).toNumber();
            function sumSelectedTransactions(accumulator, pendingAssignment){
                return accumulator.plus(pendingAssignment.transaction.amount).toDecimalPlaces(2);
            }
        },
        accountSelectOptions(){
            const vm = this;
            const nullOptions = [{ label: 'Choose a bucket', value: null }];

            const selectableAccountOptions = [...vm.sortedAssignableAccounts].map((bankAccount) => {
                return {
                    label: bankAccount.name,
                    value: bankAccount
                };
            });
            return nullOptions.concat(selectableAccountOptions);
        },
        isBulkAssignmentAllowed(){
            const vm = this;
            const hasSelectedAccount = vm.selectedAccount && !!vm.selectedAccount.value;
            const isProjectedBalanceNegative = hasSelectedAccount && vm.selectedAccount.value.balance_available < vm.totalOfSelectedTransactions;
            let errorMessage = '';
            if(isProjectedBalanceNegative){
                errorMessage = 'There is not enough money in this bucket. Please choose a different bucket.';
            }
            if(errorMessage){
                vm.bulkAssignmentError = errorMessage;
                if(vm.$refs.bulkAssignmentError){
                    Vue.nextTick(() => {
                        vm.$refs.bulkAssignmentError.focus();
                    });
                }
            }
            return hasSelectedAccount && !errorMessage && !vm.isBulkAssigningTransactions;
        },
        isBulkAssignmentPanelDisplayed(){
            const vm = this;
            return vm.selectedTransactions.length || vm.isBulkAssigningTransactions;
        },
        allTransactionsSelected: {
            get(){
                const vm = this;
                return vm.selectedTransactions.length === vm.pendingAssignmentsByDate.length;
            },
            set(isChecked){
                const vm = this;
                vm.selectedTransactions = isChecked ? vm.pendingAssignmentsByDate.slice() : [];
            }
        },
        isDragAssignmentDisabled(){
            const vm = this;
            return vm.selectedTransactions.length || vm.isBulkAssigningTransactions || vm.isMobileScreenSize;
        },
        bankAccountsForEdit(){
            return this.currentSavingsAccount.id ? [this.currentSavingsAccount] : [];
        },
        linkedBankAccountsForEdit(){
            return this.bankAccountsForEdit.filter(bankAccount => !!bankAccount.institution_account);
        },
    };
}

function getWatchers(){
    return {
        isFetchingDataFromPlaid(oldStatus, newStatus){
            if(oldStatus && !newStatus){
                created.apply(this);
            }
        },
        assignableTransactions(newTransactions){
            const vm = this;
            vm.setPendingAssignments();
        },
        isFinishedAssigningAllTransactions(hasCompletedAssignments){
            const vm = this;
            const user = vm.$store.getters.user;
            const isUserAccountOlderThanThreeMonths = Vue.moment().subtract(3, 'months').isAfter(user.current_account.created_at);
            if(hasCompletedAssignments && isUserAccountOlderThanThreeMonths){
                Vue.iosInAppReview.requestReview();
            }
        },
        '$store.state.assignableAccounts.errorMessage'(newValue){
            this.apiErrors.push(newValue);
        },
        '$store.state.assignableAccounts.data'(){
            this.setAssignableAccounts();
        },
        currentSavingsAccount(){
            this.setAssignableAccounts();
            this.selectedTransactions = [];
        },
        selectedTransactions(newSelectedTransactions){
            const isAllSelected = newSelectedTransactions.length === this.pendingAssignments.length;
            this.selectAllTransactions = isAllSelected;
        }
    };
}

function getMethods(){
    return {
        setAssignableAccounts,
        calculateAssignedTotal,
        calculatePartialPaymentTotal,
        calculateAssignmentBalanceAdjustment,
        loadAssignableTransactions,
        setPendingAssignments,
        loadAssignableAccounts,
        removeAssignment,
        toggle,
        assignTransaction,
        applyMoveStyles,
        resetDropTransactionHere,
        editUnassignedTransactions,
        displayApiError,
        sortByTransactionDate,
        bulkAssignTransactions,
        toggleDragAndDropFeature,
        loadSavingsAccount,
        refreshAllData,
        openAccountSchedule,
        openMoveMoneyModal,
        isTransactionSelected,
        editTransaction,
        hasUnassignedTransaction,
        getUnassignedBalance,
        select,
        updateChart,
        updateBankAccountBalances,
        refreshBankAccountBalances,
        onChangeSelectAllTransactions,
        openTransactionAutomationModal,
        refreshAccounts,
        updateTransactionList,
        loadAccountTransactions,
    };

    function setAssignableAccounts(){
        const vm = this;
        const data = cloneDeep(vm.$store.state.assignableAccounts.data);
        const assignableBankAccounts = data.map((bankAccount) => {
            bankAccount.loading = false;
            bankAccount.isLoadingTransaction = false;
            bankAccount.apiError = '';
            return bankAccount;
        }).sort(this.byModifiedStoreOrder);

        vm.assignableAccounts = assignableBankAccounts;
        vm.updateChart();
    }

    function calculateAssignedTotal(assignments){
        const total = assignments.reduce((sum, assignment) => sum.plus(assignment.transaction.amount).toDecimalPlaces(2), new Decimal(0));
        return total.toNumber();
    }

    function calculatePartialPaymentTotal(assignments){
        const total = assignments.reduce((accumulator, assignment) => accumulator.plus(assignment.allocated_amount || 0).toDecimalPlaces(2), new Decimal(0));
        return total.toNumber();
    }

    function calculateAssignmentBalanceAdjustment(assignments){
        const vm = this;
        const assignmentBalanceAdjustment = new Decimal(vm.calculateAssignedTotal(assignments)).minus(vm.calculatePartialPaymentTotal(assignments)).toDecimalPlaces(2);
        return assignmentBalanceAdjustment.toNumber();
    }

    function loadAssignableTransactions(){
        const vm = this;
        return vm.$store.dispatch('authorized/transactions/FETCH_UNASSIGNED_TRANSACTIONS', 'savings');
    }

    function setPendingAssignments(){
        const vm = this;
        vm.pendingAssignments = vm.assignableTransactions.map(formatTransactionAsAssignment);

        const updatedSelectedTransactions = vm.pendingAssignments.filter(({ transaction }) => vm.isTransactionSelected(transaction));
        vm.selectedTransactions = updatedSelectedTransactions;
    }

    function loadAssignableAccounts(isInitialLoad, accountId){
        const vm = this;
        if(!isInitialLoad){
            vm.isBankAccountStoreOutOfSync = true;
        }

        return this.$store.dispatch('assignableAccounts/GET_DATA', { force: true, accountId });
    }

    function removeAssignment(assignment, assignableAccount){
        const vm = this;
        vm.$set(assignableAccount, 'loading', true);
        const index = assignableAccount.untransferred_assignments.findIndex(({ id }) => id === assignment.id);
        assignableAccount.untransferred_assignments.splice(index, 1);

        return Vue.appApi().authorized().bankAccount(assignment.bank_account_id).assignment(assignment.id).deleteAssignment()
            .then(() => {
                vm.$store.commit('assignableAccounts/REMOVE_TRANSACTION', { id: assignableAccount.id, transaction: assignment });
                vm.refreshAccounts();
            })
            .catch(displayErrors)
            .finally(resetLoadingState);

        function displayErrors(response){
            if(response.appMessage){
                vm.apiErrors.push(response.appMessage);
                assignableAccount.untransferred_assignments.splice(index, 0, assignment);
            }
        }
        function resetLoadingState(){
            assignableAccount.loading = false;
        }
    }

    function toggle(assignableAccount){
        const vm = this;

        vm.$set(vm.accountCardExpanded, assignableAccount.id, !vm.accountCardExpanded[assignableAccount.id]);
        vm.$set(assignableAccount, 'showAllAssignments', false);
    }

    function assignTransaction(bankAccount, event){
        const vm = this;
        vm.selectedTransactions = [];
        var transaction_id = parseInt(event.item.getAttribute('data-id'));
        var pendingAssignmentIndex = vm.assignableTransactions.findIndex(assignment => assignment.id === transaction_id);
        var pendingAssignment = formatTransactionAsAssignment(vm.assignableTransactions[pendingAssignmentIndex]);
        var newAccountBalance = pendingAssignment && new Decimal(bankAccount.balance_available).minus(pendingAssignment.transaction.amount).toDecimalPlaces(2).toNumber();
        const isExpenseTransaction = pendingAssignment && pendingAssignment.transaction.amount > 0;
        const shouldKeepBalance = !isExpenseTransaction;
        if(shouldKeepBalance){
            newAccountBalance = bankAccount.balance_available;
        }
        const assignmentCancellationMessage = getAssignmentCancellationMessage();
        const shouldCancelAssignment = !!assignmentCancellationMessage;
        if(shouldCancelAssignment){
            bankAccount.untransferred_assignments?.splice(pendingAssignmentIndex, 1);
            vm.pendingAssignments.push(pendingAssignment);
            bankAccount.apiError = assignmentCancellationMessage;
            Vue.nextTick(() => {
                vm.$refs[`assignable-account-error-${bankAccount.id}`][0].focus();
            });
            return false;
        } else {
            vm.$set(bankAccount, 'loading', true);
            return Vue.appApi().authorized().bankAccount(bankAccount.id).assignment().postAssignTransaction({ transaction_id })
                .then((response) => {
                    const { assignment } = response.data;
                    vm.updateTransactionList(bankAccount, transaction_id, assignment, assignment.transaction.amount);
                    vm.refreshAccounts();
                })
                .catch(displayErrors)
                .finally(resetLoadingState);
        }

        function displayErrors(response){
            vm.apiErrors.push(response.appMessage);
        }

        function resetLoadingState(){
            vm.$set(bankAccount, 'loading', false);
            if(vm.shouldClosePayoffAfterAssign){
                vm.accountCardExpanded['payoff'] = false;
                vm.shouldClosePayoffAfterAssign = false;
            }
        }

        function getAssignmentCancellationMessage(){
            let assignmentCancellationMessage = '';
            const balanceWillBeNegative = pendingAssignment && newAccountBalance < 0;
            if(balanceWillBeNegative){
                assignmentCancellationMessage = 'There is not enough money in this bucket. Please choose a different bucket.';
            }

            return assignmentCancellationMessage;
        }
    }

    function applyMoveStyles(event, originalEvent){
        const vm = this;

        vm.resetDropTransactionHere();
        if(event.to.id !== null){
            // Open Payoff Placeholder if it's a payoff bucket
            const toPayoffAccount = event.to.id === 'payOffBucket';
            const shouldOpenPayoffPlaceholder = toPayoffAccount && !vm.accountCardExpanded['payoff'];
            if(shouldOpenPayoffPlaceholder){
                vm.accountCardExpanded['payoff'] = true;
                vm.shouldClosePayoffAfterAssign = true;
                vm.$forceUpdate();
                return;
            }

            // Add a frame
            var dropAssignableAccountElement = document.getElementById(event.to.id + '-body');
            if(dropAssignableAccountElement){
                dropAssignableAccountElement.classList.add('assignable-account-border-' + dropAssignableAccountElement.dataset.color);
            }
        }

        return true;
    }

    function loadSavingsAccount(){
        const vm = this;
        const queryParams = { slug: 'primary_savings' };
        return Vue.appApi().authorized().bankAccount().loadWithInstitutionAccounts(queryParams).then(setSavingsAccounts).catch(vm.displayApiError);

        function setSavingsAccounts(response){
            if(response.data){
                vm.allSavingsAccounts = response.data || [];
                const currentBankAccountId = vm.$route.params.bank_account_id;
                const currentSavingsAccount = vm.allSavingsAccounts.filter(bankAccount => `${bankAccount.id}` === `${currentBankAccountId}`)[0] || vm.allSavingsAccounts[0];
                if(currentSavingsAccount){
                    vm.currentSavingsAccount = currentSavingsAccount;
                } else {
                    vm.currentSavingsAccount = {};
                }
            }
            return vm.currentSavingsAccount;
        }
    }

    function resetDropTransactionHere(){
        const vm = this;
        vm.sortedAssignableAccounts.forEach(resetMoveStyles);

        function resetMoveStyles(assignableAccount){
            var assignableAccountElement = document.getElementById('assignableAccount-' + assignableAccount.id + '-body');
            if(assignableAccountElement !== null){
                assignableAccountElement.classList.remove('assignable-account-border-' + assignableAccountElement.dataset.color);
            }
            var assignableAccountElementParent = document.getElementById('assignableAccount-' + assignableAccount.id + '-header-parent');
            if(assignableAccountElementParent){
                assignableAccountElementParent.style.display = 'none';
            }
        }
    }

    function editUnassignedTransactions(){
        const vm = this;
        vm.$refs.editTransactionsModal.openModal();
    }

    function displayApiError(response){
        const vm = this;
        if(response && response.appMessage){
            vm.apiErrors.push(response.appMessage);
        }
    }

    function sortByTransactionDate(a, b){
        const firstDate = a.transaction ? a.transaction.remote_transaction_date : a.remote_transaction_date;
        const secondDate = b.transaction ? b.transaction.remote_transaction_date : b.remote_transaction_date;
        return Vue.moment(firstDate).isAfter(secondDate) ? -1 : 1;
    }

    function bulkAssignTransactions(){
        const vm = this;
        vm.isBulkAssigningTransactions = true;
        const bankAccount = vm.selectedAccount.value;
        const transaction_ids = vm.selectedTransactions.map(({ transaction }) => transaction.id);
        transaction_ids.forEach((id) => vm.$store.commit('authorized/transactions/REMOVE_UNASSIGNED_TRANSACTION', { id }));
        vm.$set(bankAccount, 'loading', true);
        const selectedTransactions = vm.selectedTransactions;
        vm.selectedTransactions = [];

        return Vue.appApi().authorized().bankAccount(bankAccount.id).assignment().postAssignTransaction({ transaction_ids })
            .then((response) => {
                response.data.forEach(({ assignment }) => assignTransaction(assignment));
                vm.refreshAccounts();
            })
            .catch(handleAssignmentErrors)
            .finally(resetLoadingState);

        function assignTransaction(assignment){
            const { transaction_id } = assignment;
            vm.$store.commit('authorized/transactions/REMOVE_UNASSIGNED_TRANSACTION', { id: transaction_id });
            vm.updateTransactionList(bankAccount, transaction_id, assignment, assignment.transaction.amount);
        }

        function handleAssignmentErrors(error){
            vm.selectedTransactions = selectedTransactions;
            vm.selectedTransactions.forEach(({ transaction }) => vm.$store.commit('authorized/transactions/ADD_UNASSIGNED_TRANSACTION', transaction));
            vm.bulkAssignmentError = error.appMessage || (error.data && error.data.message);
        }

        function resetLoadingState(){
            vm.$set(bankAccount, 'loading', false);
            vm.isBulkAssigningTransactions = false;
            vm.selectedAccount = vm.accountSelectOptions[0];
        }
    }

    function toggleDragAndDropFeature(){
        const vm = this;
        const mobileBreakpoint = 576;
        if(vm.isMobileScreenSize && window.innerWidth > mobileBreakpoint){
            vm.isMobileScreenSize = false;
        } else if(!vm.isMobileScreenSize && window.innerWidth <= mobileBreakpoint){
            vm.isMobileScreenSize = true;
        }
    }

    function openAccountSchedule(bankAccount){
        if(!bankAccount.hasAccountSchedule){
            return;
        }

        const vm = this;
        vm.selectedBankAccount = bankAccount;
        Vue.nextTick(() => {
            vm.$refs.accountScheduleModal.show();
            vm.toggle(bankAccount);
        });
    }

    function openMoveMoneyModal(fromAccount){
        this.$refs.moneyMoverModal.openModal(fromAccount);
        this.toggle(fromAccount);
    }

    async function refreshAllData(_isInitialLoad = false){
        const vm = this;
        const isInitialLoad = _isInitialLoad === true;
        if(isInitialLoad){
            if(!vm.$store.state.authorized.bankAccounts.bankAccounts.length){
                vm.$store.dispatch('authorized/bankAccounts/FETCH_BANK_ACCOUNTS');
            }
        }
        const savingsAccount = await vm.loadSavingsAccount();
        const dataPromises = [
            vm.loadAssignableAccounts(isInitialLoad, savingsAccount.id),
            vm.loadAssignableTransactions(),
        ];

        return Promise.all(dataPromises).then(onRefreshComplete);

        function onRefreshComplete(){
            if(isInitialLoad){
                vm.updateBankAccountBalances();
                vm.setAssignableAccounts();
                vm.isInitializingView = false;
            }
        }
    }

    function refreshAccounts(){
        const vm = this;
        const dataPromises = [
            vm.loadSavingsAccount(),
            vm.loadAssignableTransactions(),
        ];

        return Promise.all(dataPromises).then(onRefreshComplete);

        function onRefreshComplete(){
            vm.setAssignableAccounts();
        }
    }

    function updateTransactionList(bankAccount, transactionId, assignment, amount){
        const vm = this;
        const bankAccountId = bankAccount.id;
        vm.$store.commit('authorized/transactions/REMOVE_UNASSIGNED_TRANSACTION', { id: transactionId });
        vm.$store.commit('assignableAccounts/ADD_TRANSACTION', { id: bankAccountId, transaction: assignment });
    }

    function updateBankAccountBalances(){
        const vm = this;
        vm.assignableAccounts.forEach(updateBankAccountBalance);

        function updateBankAccountBalance(bankAccount){
            vm.$store.commit('authorized/bankAccounts/UPDATE_BANK_BALANCE_PROPERTIES', bankAccount);
        }
    }

    function refreshBankAccountBalances(){
        this.$store.commit('assignableAccounts/REFRESH_BANK_ACCOUNT_BALANCES', this.$store.state.authorized.bankAccounts.bankAccounts);
        this.setAssignableAccounts();
    }

    function isTransactionSelected(transactionToCheck){
        return this.selectedTransactions.some(({ transaction }) => transaction.id === transactionToCheck.id);
    }

    function editTransaction(transaction){
        const vm = this;
        const isCustomTransaction = !transaction.parent_transaction_id && !transaction.remote_transaction_id;
        if(isCustomTransaction){
            vm.$refs.customTransactionModal.openModal(transaction);
        } else {
            vm.$refs.splitTransactionModal.openModal(transaction);
        }
    }

    function hasUnassignedTransaction(savingsAccount){
        const countUnassignedTransactions = JSON.parse(JSON.stringify(this.$store.state.authorized.transactions.unassignedTransactions)).filter(filterBySavingsAccount).length;
        return countUnassignedTransactions > 0;

        function filterBySavingsAccount(transaction){
            return transaction.bank_account_id === savingsAccount.id;
        }
    }

    function getUnassignedBalance(savingsAccount){
        const notValid = !savingsAccount || !savingsAccount.sub_accounts;
        if(notValid){
            return 0;
        }

        const unassignedBalance = savingsAccount.sub_accounts.reduce((accumulator, subAccount) => accumulator.minus(new Decimal(subAccount.balance_available)), new Decimal(savingsAccount.balance_current));
        return unassignedBalance.toDecimalPlaces(2);
    }

    function select(savingsAccount){
        this.isInitializingView = true;
        this.currentSavingsAccount = savingsAccount;
        this.$router.replace({
            name: this.$route.name,
            params: { 'bank_account_id': savingsAccount.id }
        });
        this.$nextTick(() => {
            this.refreshAllData(true);
        });
    }

    function updateChart(){
        const vm = this;
        const chartData = {
            labels: [],
            series: [],
        };

        const chartAccountList = vm.sortedAssignableAccounts;
        const accounts = cloneDeep(chartAccountList);
        if(accounts.length === 1){
            const copyAccount = Object.assign({}, accounts[0]);
            accounts.push(copyAccount);
            accounts[0].balance_available = 0;
        }

        accounts.forEach(addAccountToChartData);
        vm.chartData = chartData;

        function addAccountToChartData(bankAccount){
            if(bankAccount !== null){
                var className = `${bankAccount.color}-slice`;
                var balance = bankAccount.balance_available;
                const label = vm.formatAsDecimal(balance, 'currency');
                const seriesItem = {
                    className,
                    value: Math.max(0, balance),
                    meta: {
                        id: bankAccount.id,
                        target: `ct-${bankAccount.id}`,
                    }
                };

                chartData.series.push(seriesItem);
                chartData.labels.push(label);
            }
        }
    }

    function onChangeSelectAllTransactions(){
        const vm = this;
        if(vm.selectAllTransactions){
            vm.selectedTransactions = vm.pendingAssignments;
        } else {
            vm.selectedTransactions = [];
        }
    }

    function openTransactionAutomationModal(bankAccount){
        const vm = this;
        Vue.nextTick(() => {
            vm.$refs.transactionAutomationModal.openModal(bankAccount);
            vm.toggle(bankAccount);
        });
    }

    function loadAccountTransactions(account, showAll = ''){
        const vm = this;
        if(!account.untransferred_assignments || showAll !== ''){
            account.isLoadingTransaction = true;
            return this.$store.dispatch('assignableAccounts/FETCH_ACCOUNT_TRANSACTIONS', { accountId: account.id, showAll })
                .then(vm.setAssignableAccounts)
                .catch(displayError);
        }

        function displayError(response){
            if(response.appMessage){
                vm.apiErrors.push(response.appMessage);
            }
        }
    }
}
function formatTransactionAsAssignment(transaction){
    return {
        allocated_amount: 0,
        transaction
    };
}
