import { render, staticRenderFns } from "./dashboard.vue?vue&type=template&id=b8230ec0&scoped=true"
import script from "./dashboard.controller.js?vue&type=script&lang=js&external"
export * from "./dashboard.controller.js?vue&type=script&lang=js&external"
import style0 from "./_dashboard.scss?vue&type=style&index=0&id=b8230ec0&prod&lang=scss&scoped=true&external"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "b8230ec0",
  null
  
)

export default component.exports