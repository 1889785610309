import { render, staticRenderFns } from "./account-form.vue?vue&type=template&id=56537270&scoped=true"
import script from "./account-form.controller.js?vue&type=script&lang=js&external"
export * from "./account-form.controller.js?vue&type=script&lang=js&external"
import style0 from "./_account-form.scss?vue&type=style&index=0&id=56537270&prod&lang=scss&scoped=true&external"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "56537270",
  null
  
)

export default component.exports