import braintreeWebDropIn from 'braintree-web-drop-in';

export default {
    data: data,
    computed: getComputed(),
    beforeDestroy,
    methods: getMethods(),
    watch: {
        '$store.state.subscriptionTypes.errorMessage'(newValue){
            this.apiErrors.push(newValue);
        },
        '$store.state.subscriptionTypes.data'(newValue){
            this.setSubscriptionTypes(newValue);
        },
        isModalActive(newValue){
            if(newValue === true){
                this.getSubscriptionTypes();
            }
        }
    },
    props: {
        fullScreen: {
            type: Boolean,
            default: false
        }
    },
};

function data(){
    return {
        subscriptionType: 'monthly_4_99',
        subscriptionPlan: 'plus',
        braintreeClientToken: null,
        braintreeInstance: null,
        processingPayment: false,
        mode: 'purchase',
        apiErrors: [],
        subscriptionTypesForPurchase: [],
        braintreeMerchantId: null,
        isModalActive: false
    };
}

function getComputed(){
    return {
        user(){
            return this.$store.state.guest.user.user;
        },
        activeDiscountPercent(){
            let discountPercentage = 0;

            if(this.user && this.user.current_account.active_discount_coupon){
                discountPercentage = this.user.current_account.active_discount_coupon.amount;
            }

            return discountPercentage;
        },
        activeDiscountDuration(){
            let discountDuration = 0;

            if(this.user.current_account.active_discount_coupon){
                discountDuration = this.user.current_account.active_discount_coupon.remaining_months > 1 ? (this.user.current_account.active_discount_coupon.remaining_months + ' months') : 'month';
            }

            return discountDuration;
        },
        selectedSubscriptionPrice(){
            let price = 0;

            const selectedType = this.subscriptionTypesForPurchase.find(({ value }) => value === this.subscriptionType);

            if(selectedType){
                price = selectedType.price;
            }

            return price;
        },
        priceLessDiscount(){
            const discountAmount = new Decimal(this.selectedSubscriptionPrice).times((this.activeDiscountPercent / 100)).toDecimalPlaces(2).toNumber();

            return new Decimal(this.selectedSubscriptionPrice).minus(discountAmount).toDecimalPlaces(2).toNumber();
        }
    };
}

function beforeDestroy(){
    const vm = this;
    vm.mode = 'purchase';
    vm.cleanupBraintree();
}

function getMethods(){
    return {
        setSubscriptionTypes,
        openModal,
        getClientToken,
        showBraintreeDropinForm,
        submitPaymentInfo,
        cleanupBraintree,
        getSubscriptionTypes,
        setModalActive
    };

    function setSubscriptionTypes(data){
        const isUserBusinessAccount = this.user.is_business_account;
        const availableSubscriptionTypes = Object.values(data).filter((subscription) => {
            if(isUserBusinessAccount && subscription.slug === 'monthly_99'){
                return subscription;
            }
            if(!isUserBusinessAccount && subscription.cleared_for_sale && subscription.slug !== 'monthly_99'){
                return subscription;
            }
        });
        this.subscriptionTypesForPurchase = availableSubscriptionTypes.map(formatSubscriptionTypeOptions).sort(sortByPrice);
        const currentSubscriptionType = this.subscriptionTypesForPurchase.find(({ value }) => value === this.user?.current_account.subscription_type);
        this.subscriptionType = currentSubscriptionType ? currentSubscriptionType.value : this.subscriptionTypesForPurchase[0].value;

        function formatSubscriptionTypeOptions(subscriptionType){
            const subscriptionTypeOption = {
                value: subscriptionType.slug,
                text: `$${subscriptionType.price} - ${subscriptionType.name}`,
                price: subscriptionType.price
            };
            return subscriptionTypeOption;
        }
        function sortByPrice(a, b){
            return a.price - b.price;
        }
    }

    function openModal(mode = 'purchase'){
        const vm = this;
        this.$nextTick(() => {
            vm.mode = mode;
        });
        vm.showBraintreeDropinForm();
        vm.$refs.subscriptionModal.show();
    }

    function getClientToken(){
        const vm = this;
        return Vue.appApi().guest().braintree().getClientAuthToken().then(setClientToken).catch(showError);

        function setClientToken(response){
            vm.braintreeClientToken = response.data.token;
            vm.braintreeMerchantId = response.data.merchant_id;
        }
        function showError(response){
            vm.apiErrors.push(response.appMessage || response.data.message);
        }
    }

    function showBraintreeDropinForm(){
        const vm = this;
        if(!vm.braintreeClientToken){
            return vm.getClientToken().then(vm.showBraintreeDropinForm);
        }
        braintreeWebDropIn.create(
            {
                authorization: vm.braintreeClientToken,
                container: '#drop-in-container',
                vaultManager: true,
                card: {}
            },
            function(createErr, instance){
                if(createErr){
                    vm.apiErrors.push(`Oops, we're having trouble communicating with our payment processor. Please try again later.`);
                } else {
                    vm.braintreeInstance = instance;
                }
            }
        );
    }

    function submitPaymentInfo(){
        const vm = this;
        vm.processingPayment = true;
        vm.apiErrors = [];
        vm.braintreeInstance.requestPaymentMethod().then(storePayment).catch(showError);

        function storePayment(payload){
            vm.apiErrors = [];
            const subscriptionDetails = {
                paymentNonce: payload.nonce,
                subscriptionType: vm.subscriptionType,
                subscriptionPlan: vm.subscriptionPlan
            };
            let processPaymentPromise = null;
            if(vm.mode === 'purchase'){
                processPaymentPromise = vm.$store.dispatch('user/PURCHASE_SUBSCRIPTION', subscriptionDetails);
            } else if(vm.mode === 'update'){
                processPaymentPromise = Vue.appApi().authorized().account().updateSubscriptionPayment({ paymentNonce: payload.nonce });
            } else if(vm.mode === 'signup'){
                processPaymentPromise = Vue.appApi().authorized().account().updateSubscriptionPayment(subscriptionDetails);
            } else {
                processPaymentPromise = new Promise((resolve, reject) => reject('Unknown subscription modal mode.'));
            }

            processPaymentPromise.then(submitPaymentSuccess).catch(submitPaymentError);

            function submitPaymentSuccess(){
                vm.processingPayment = false;
                vm.$emit('paymentSubmittedSuccess', vm.mode);
                vm.$refs.subscriptionModal.hide();
            }
            function submitPaymentError(){
                vm.processingPayment = false;
                vm.apiErrors.push('Unable to submit. Please check your credit card information. If the problem persists, please contact support@trueview.money.');
            }
        }
        function showError(requestPaymentMethodErr){
            vm.processingPayment = false;
            if(requestPaymentMethodErr){
                vm.apiErrors.push(requestPaymentMethodErr.message);
            }
        }
    }

    function cleanupBraintree(){
        const vm = this;
        if(vm.braintreeInstance instanceof Object){
            vm.braintreeInstance.teardown().then(() => {
                vm.braintreeInstance = null;
            });
        }
        vm.braintreeClientToken = null;
        vm.isModalActive = false;
    }

    function getSubscriptionTypes(){
        this.$store.dispatch('subscriptionTypes/GET_DATA');
    }

    function setModalActive(){
        this.isModalActive = true;
    }
}
